@font-face {
  font-family: "Ibmplex-sans";
  src: url("assets/fonts/IBMPlexSans-Medium.otf");
}

@tailwind base;
@layer base {
  :root {
    --primary-color: #efaa0f;
    --secondry-color: #202021;
    --third-color: #f0f2f5;
    --primary-font: #414141;
    --secondry-font: #fff;
    --third-font: #ccc;
    --primary-border: #ddd;

    --d-primary-color: #3790ed;
    --d-secondry-color: #1f283e;
    --d-third-color: #1a2035;
    --d-primary-font: #ddd;
    --d-secondry-font: #fff;
    --danger-color: #f44335;
    --d-primary-border: #545d72;
    --success-color: #4caf50;
    --warning-color: #ffbf2f;
  }
  body {
    font-family: "Ibmplex-sans", Tahoma, Arial !important;
    @apply bg-third dark:bg-third-dark overflow-x-hidden;
  }
  h1 {
    @apply text-[1.4em] sm:text-[1.8em] !important;
  }
  h2 {
    @apply text-[1.25em] sm:text-[1.5em] !important;
  }
  h3 {
    @apply text-[1.15em] sm:text-[1.3em] !important;
  }
  h4 {
    @apply text-[.95em] sm:text-[1em] !important;
  }
  h5 {
    @apply text-[.85em] sm:text-[0.8em] !important;
  }
  h6 {
    @apply text-[.8em] sm:text-[0.7em] !important;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-bold !important;
  }
  span {
    @apply text-xs xl:text-[.8rem];
  }
  p,
  label,
  li {
    @apply text-sm md:text-[1rem];
  }
  input,
  select {
    @apply text-[0.875rem] sm:text-[1rem] !important;
  }
  table th {
    @apply text-sm md:text-lg;
  }
  table tr {
    @apply text-xs md:text-sm !important;
  }
}
@tailwind components;
@tailwind utilities;
@layer utilities {
  .clip-polygon {
    -webkit-clip-path: polygon(88% 0, 100% 100%, 75% 100%, 0% 100%, 0 50%, 0% 0%);
    clip-path: polygon(88% 0, 100% 100%, 75% 100%, 0% 100%, 0 50%, 0% 0%);
  }
}
